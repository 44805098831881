.bridge-card{
    .ant-card-body{
        width: 100%;
    }
}
.bridge{
    max-width: 400px;
    margin: auto;
    .bridgeChain{
        display: flex;
        justify-content: space-between;
        .bridgeFrom{
            font-weight: bold;
            flex: 1;
        }
        .bridgeTo{
            font-weight: bold;
            flex: 1;
        }
    }
    .bridgeAmount{
        margin-top: 18px;
        .bridgeAmountBalance{
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            font-size: 16px;
        }
    }
    .bridgeline{
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        .lineWord{
            font-weight: bold;
            font-size: 16px;
        }
        .valueWord{
            font-size: 16px;
        }
    }
}

.ant-statistic .ant-statistic-content{
    font-size: 16px!important;
}