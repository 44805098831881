.top-menu{
    background-color: #fff;
    // width: 100%;
    // position: fixed;
    // top: 0;
    // z-index: 10;
}

.top-menu-container{
    padding-top: 12px;
    padding-bottom: 12px;
    // max-width: 1296px;
    width: 100%;
    // display: block;
    // box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    color: #0f172a;
}

.top-menu-content{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
}
.top-menu-right{
    display: flex;
    align-items: center;
    justify-content: space-around;
    // min-width: 300px;
}
.banner-wrap{
    // margin-top: 52px;
    // background-color: #0497ec;
    // background-color: #068fdf;
    background-color: #8a6a51;
    // height: 100vh;
    .banner-center-container{
        padding-left: 20px;
        display: flex;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        position: relative;
        // max-width: 1180px;
        height: 710px;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        .center-text-container{
            display: flex;
            flex-direction: column;
            gap: 30px;
            // width: 57%;

            .center-title{
                // max-width: 944px;
                display: flex;

                .left-border{
                    height: 160px;
                    border-left: 2px solid #0f172a;
                    margin-right: 20px;
                    margin-top: 50px;
                }

                .center-content{
                    display: flex;
                    flex-direction: column;
                    // gap: 25px;
                    margin-top: 50px;

                    .main-title{
                        font-style: normal;
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: 36px;
                        line-height: 40px;
                    }
                }
            }

            .crad-container{
                margin-top: 130px;
                min-height: 170px;

                .card-content{
                    background: #fff;
                    border: 1px solid #0f172a;
                    border-bottom-width: 2px;
                    border-radius: 5px;
                    margin-bottom: 8px;
                    border-radius: 30px;
                    display: flex;
                    align-items: center;
                    min-height: 170px;
                    // max-width: 568px;
                    width: 100%;

                    .stat-content{
                        // margin-top: 50px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                    }
                }
            }

            @media screen and (max-width: 768px) {
                .crad-container {
                    margin-top: 130px;
                    min-height: 190px;

                    .card-content{
                        background: #fff;
                        border: 1px solid #0f172a;
                        border-bottom-width: 2px;
                        border-radius: 5px;
                        margin-bottom: 8px;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        min-height: 190px;
                        // max-width: 568px;
                        width: 100%;
    
                        .stat-content{
                            // margin-top: 50px;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .center-img{
            // position: absolute;
            // right: -40px;
            max-height: 580px;
            width: 45vw;
            // max-width: 600px;
            height: 100%;
            // margin-left: 50px;
            -o-object-fit: contain;
            object-fit: contain;
            image-rendering: auto;
        }
    }
}

.main-container{
    // background-color: #e3eafd;
    // background-color: #dee6fc;
    background-color: #e2ccba;
}

.cards-container{
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px 70px 20px;
    // height: 100vh;

    .single-card{
        // width: 220px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        .ant-carousel .slick-dots-bottom{
            bottom: -12px!important;
        }

        .single-title{
            text-transform: none;
            margin-bottom: 10px;
            margin-top: 20px;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 29px;
        }
        .single-card-container{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            max-width: 220px;
            text-align: center;

            .card-img{
                width: 100%;
                padding: 5px;
                min-height: 253px;
                border: 1px solid #0f172a;
                border-bottom: 2px solid #0f172a;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;

                .image{
                    height: 100%;
                    width: 100%;
                    padding: 7px;
                }
            }
            .sub-title{
                margin-top: 10px;
                margin-bottom: 20px;
                text-transform: none;
                font-size: 22px;
                line-height: 29px;
                font-style: normal;
                font-weight: 700;
            }
            .comment{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #000;
            }
        }
    }
}


.key-feature{
    // max-width: 1180px;
    margin: 0 auto;
    padding: 0 15px 70px 15px;
    // height: 100vh;

    .title{
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
    }

    .feature-card{
        background-color: white;
        border-radius: 8px 8px 0 0;

        .feature-content{
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            font-size: 16px;
            font-weight: 400;
            min-height: 225px;
            padding: 16px;
            .feature-list{
                // width: 70%;
                padding-left: 30px;

                .list-item{
                    font-size: 16px;
                    line-height: 30px;
                    color: #0f172a;
                    // width: 700px;
                }
            }

            .feature-img{
                width: 160px;
                height: 140px;
                // margin-right: 50px;
            }
        }
    }
}

.chart-container{
    // max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    // height: 500px;
    padding-bottom: 100px;

    .chart-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;

        .title{
            text-transform: uppercase;
            margin-bottom: 30px;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 37px;
        }

        .value{
            font-weight: 600;
        }
    }

    .chart-content{
        margin: 0 20px;

        .card-content-container{
            display: flex;
            flex-direction: column;

            .card-chain-blocks{
                display: flex;
                gap: 20px;
                flex-wrap: wrap;

                // .chain-block{
                //     width: 250px;
                //     border: 1px solid #000;
                //     border-bottom: 2px solid #000;
                //     border-radius: 12px;
                //     padding: 7px 0;
                //     display: flex;
                //     align-items: center;
                //     justify-content: space-around;    
                // }

                // .block-text{
                //     font-weight: 400;
                //     font-size: 16px;
                //     line-height: 21px;
                // }
            }
        }
    }
}
.chain-block{
    width: 250px;
    border: 1px solid #000;
    border-bottom: 2px solid #000;
    border-radius: 12px;
    padding: 7px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;    
}

.block-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.audit-container{
    // max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px 80px;
    // height: 500px;

    .text-container{

        .audit-content{
            display: flex;
            justify-content: space-between;

            .audit-img{
                width: 140px;
                height: 140px;
                margin-right: 50px;

                .img-audit{
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .title{
            text-transform: uppercase;
            margin-bottom: 30px;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 37px;
        }

        .audit-text{
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 30px;
            max-width: 760px;
        }
    }
}

.update-card-container{
    display: flex;
}