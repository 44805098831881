/* Data */
.data {
  margin: 40px;
}

.desc{
  padding: 15px 20px;
  margin: 10px 10px 30px;
}

.line{
 margin: 30px auto;;
}

.ant-descriptions-item-content {
  flex-direction: column;
}
