
.loading-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    .spin-loading{
        margin-top: 20px;
    }
    .loading-first-line{
        margin-top: 30px;
        color: #0f172a;
        font-size: 24px;
        font-weight: 600;
    }
    .loading-second-line{
        font-size: 20px;
        font-weight: 600;
        color: #687386;
    }
}