.login-container {
  background     : url("../images/bg.svg");
  background-size: cover;
  height         : 100vh;
  display        : flex;
  align-items    : center;
  justify-content: center;

  .wrapper {
    text-align: center;

    .title {
      font-size  : 28px;
      font-weight: bold;
    }

    .welcome {
      margin-top: 20px;
    }

    .login-form {
      width : 300px;
      margin: 50px auto;
    }



    .btns {
      display        : flex;
      justify-content: space-around;
      .login-form-button{
        margin-right: 10px;
      }

      .ant-form-item-control-input-content {
        display        : flex;
        justify-content: space-between;
      }

      .ant-btn {
        // width: 100%;
        width: 60%;
      }
    }

    .ant-form label {
      float: left;
    }

    .ant-form-item-explain-error {
      text-align: left;
    }
  }
}

.top-menu-app{
  padding: 0 20px;
  background-color: white;
}
.top-container-app{
    // padding-top: 12px;
    // padding-bottom: 12px;
    // max-width: 1296px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    color: #0f172a;
    display: flex;
  //  justify-content: space-between;
}
// @media (min-width: 1400px) {
  
// }
.top-menu-click{
  cursor: pointer;
}
.top-btn{
  position: absolute;
  top: 0;
  right: 10px;
}
.top-right{
  display: flex;
  align-items: center;
}
.top-right-phone{
  display: none;
}
@media only screen and (max-width: 768px) {
  .top-right{
    display: none;
  }
  .top-right-phone{
    display: flex;
  }
  .custom-modal .ant-modal{
    width: 400px!important;
  }
}
.address-info{
  width: 116px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: none;
  border-color: #4DB258;
  // background-color: #1C1E32;
  // color: #D0D0DA;
  border: 2px solid #4DB258;
  border-radius: 8px;
  padding: 4px 16px;
  font-size: 16px;
  cursor: pointer;
}