.main-container-blue{
    // background-color: #0497ec;
    // background-color: #068fdf;
    background-color: #8a6a51;
    max-width: 100%;
}

.footer-container{
    max-width: 1180px;
    margin: 0 auto;
    // display: flex;
    // justify-content: space-between;
    padding: 80px 15px;
    // height: 100px;

    .first-column{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        // align-items: center;

        .title-container{
            display: flex;
            gap: 20px;

            .title-border{
                border-left: 2px solid #0f172a;
                height: 159px;
            }
            .title-content{
                display: flex;
                flex-direction: column;
                margin-bottom: 55px;

                .title{
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 37px;
                    // width: 268px;
                }
                .sub-title{
                    // width: 300px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .second-column{
        display: flex;
        flex-direction: column;
        // align-items: center;

        .second-title{
            font-weight: 700;
            font-size: 20px;
            line-height: 22px;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        .second-container{
            display: flex;
            gap: 30px;

            .second-single-col{
                display: flex;
                flex-direction: column;
                .col-bottom{
                    margin-bottom: 8px;
                }

                .link-text{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #fff;
                    cursor: pointer;
                    transition: color .15s ease;

                    &:hover{
                        color: hsla(0, 0%, 100%, .8);
                    }
                }
            }
        }
    }
}